import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { graphql, useStaticQuery } from 'gatsby'
import { ContainerDasa } from '../shared/container'
import ReactHtmlParser from 'react-html-parser'; 
import PinkLine from '../shared/PinkLine'

const AreasDeAtuacao = ({ page }) => {
  const results = useStaticQuery(
    graphql`
      query {
        wordpressWpUsers(wordpress_id: {eq: 1}) {
          id
          name
          wordpress_id
        }
        wordpressWpLogo {
          id
          url {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
  `)

  const authorDasa = results.wordpressWpUsers || false
  const logoDasa = results.wordpressWpLogo || false

  return (
    <>
      <ContainerDasa>
        <S.AreaContent>
          <S.Text>
            <div> { ReactHtmlParser (page.content) } </div>
          </S.Text>
        </S.AreaContent>

        <S.AreasTopContainer>
          <S.AuthorTop>
            <S.AuthorImgAndNameTop>
              <S.AuthorImg
                fluid={logoDasa.url.localFile.childImageSharp.fluid}
                alt={authorDasa.name}
              />

              <S.AuthorName>
                {authorDasa.name}
              </S.AuthorName>
            </S.AuthorImgAndNameTop>
          </S.AuthorTop>
        </S.AreasTopContainer>
      </ContainerDasa>
      <PinkLine />
    </>
  )
}

AreasDeAtuacao.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    date: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
}

export default AreasDeAtuacao