import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Page from 'components/areas-de-atuacao'

const AreasDeAtuacao = ({ data, location, pageContext }) => {
  const {
    wordpressPage: {
      title,
      content,
      date,
      acf: { subtitle, canonical, pageBackgroundImage: titleBackgroundImage },
    },
  } = data

  const headerInfo = titleBackgroundImage
    ? {
        title,
        subtitle,
        canonical,
        backgroundImage: titleBackgroundImage.localFile.childImageSharp,
      }
    : undefined

  return (
    <Layout location={location} pageContext={pageContext} headerInfo={headerInfo}>
      <SEO 
        title={title}
        description={subtitle}
        canonical={canonical}
        pathName={location.pathname}
      />
      <Page page={{ content, title, date }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String) {
    wordpressPage(slug: { eq: $slug }) {
      title
      acf {
        title
        subtitle
        canonical
        description
        pageBackgroundImage: page_background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      excerpt
      content
      author {
        name
      }
      date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
    }
  }
`
AreasDeAtuacao.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default AreasDeAtuacao
